<template>
  <VLayout class="text-assignment-item">
    <VFlex xs8 offset-xs2>
      <VLayout>
        <VFlex v-if="selectable" shrink>
          <VCheckbox
            :input-value="isSelected"
            class="d-inline-block pt-0"
            hide-details
            @change="toggleSelection"
          />
        </VFlex>
        <VFlex class="text-xs-left" grow>
          <VIcon
            v-if="!assignment.phone"
            class="no-phone-icon mr-1 mb-1"
            title="No Phone Number"
            small
            fab
            >error</VIcon
          >
          {{ index }}. {{ assignment.name }}
          <span v-if="assignment.numAssgnts > 1">
            <VAvatar right size="16" class="num-assignments">{{
              assignment.numAssgnts
            }}</VAvatar>
            (Job: {{ assignment.jobNumber }})
          </span>
        </VFlex>
      </VLayout>
    </VFlex>
    <VFlex v-if="error" xs12>
      <VLayout>
        <VFlex class="error--text mb-2 align-center"
          >Error: {{ error }}
          <BaseButton
            small
            color="primary"
            :loading="clearing"
            :disabled="clearing"
            @click="$emit('clear')"
            >Clear</BaseButton
          ></VFlex
        >
      </VLayout>
    </VFlex>
  </VLayout>
</template>

<script>
import VAvatar from '@vuetify/VAvatar'
import { VCheckbox } from 'vuetify/lib'

export default {
  name: 'TextAssignment',
  components: { VAvatar, VCheckbox },
  props: {
    error: {
      type: String,
      default: null,
    },
    assignment: {
      type: Object,
      required: true,
    },
    clearing: {
      type: Boolean,
      default: false,
    },
    index: {
      type: Number,
      default: 0,
    },
    isSelected: {
      type: Boolean,
    },
    selectable: {
      type: Boolean,
      default: true,
    },
  },
  methods: {
    toggleSelection() {
      this.$emit('toggle-selection', this.assignment.shiftId)
    },
  },
}
</script>
