<template>
  <VDialog
    v-model="showPopup"
    :max-width="500"
    :disabled="working"
    :persistent="working"
  >
    <VCard
      :class="['text-assgnts-popup text-xs-left', { 'grey--text': working }]"
    >
      <ModalCloseButton
        :disabled="working"
        color="white"
        @click="$emit('hide')"
      />
      <VCardTitle class="orange accent-2 white--text">
        <VFlex xs12 mb-2 text-xs-center
          ><h2>
            Text Labor Assignments
          </h2>
        </VFlex>
      </VCardTitle>
      <VCardText class="font-weight-medium text-xs-center">
        <BaseSpinner
          v-if="working"
          :message="`Sending ${numAssignedLaborers} Texts...`"
          text-fg-color="black"
        />
        <h3 v-else-if="didComplete" class="py-4"
          >Messages Sent Successfully<br />Refresh the page to see the
          results.</h3
        >
        <template v-else>
          <h3 v-if="laborerIdsWithValidPhone.length" class="py-4"
            >Text {{ assgntsToText.length }} Assignments to
            {{ numAssignedLaborers }} Laborers?</h3
          >
          <h3 v-else class="py-4"
            >All Laborers with Phone Numbers have been texted.</h3
          >
          <h4
            v-if="laborersWithSMSIssues.length && !isShowingNames"
            class="py-4"
            >There were also {{ laborersWithSMSIssues.length }} Issues
            found.</h4
          >
          <BaseButton outline @click="isShowingNames = !isShowingNames"
            >{{ isShowingNames ? 'Hide' : 'Show' }} List</BaseButton
          >
          <VLayout v-if="isShowingNames" row wrap class="my-2">
            <VFlex xs8 offset-xs2>
              <VLayout row wrap>
                <VFlex shrink>
                  <VCheckbox
                    :input-value="allSelected"
                    hide-details
                    :indeterminate="partiallySelected"
                    class="d-inline-block pt-0"
                    @change="toggleSelectAll"
                  />
                </VFlex>

                <VFlex class="text-xs-left font-weight-bold" grow>
                  {{ allSelected ? 'Deselect All' : 'Select All' }}
                </VFlex>
              </VLayout>
              <VDivider class="mb-2" />
            </VFlex>
            <VFlex
              v-for="(assignment, index) of laborersToText"
              :key="assignment.id"
              xs12
              mb-1
            >
              <TextAssignment
                :assignment="assignment"
                :index="index + 1"
                :is-selected="
                  selectedAssignmentIds.includes(assignment.shiftId)
                "
                @toggle-selection="toggleSelection"
              />
            </VFlex>
            <VFlex xs12>
              <h3
                v-if="laborersWithSMSIssues.length"
                class="text-xs-center py-4"
                >The Following Laborers have SMS issues:</h3
              >
            </VFlex>
            <VFlex
              v-for="assignment of laborersWithSMSIssues"
              :key="assignment.id"
              xs12
              mb-1
            >
              <TextAssignment
                :assignment="assignment"
                :error="assignment.smsStatus"
                :clearing="clearing"
                :selectable="false"
                @clear="clearSMSStatusForAssgnt(assignment)"
              />
            </VFlex>
          </VLayout>
        </template>
      </VCardText>
      <VCardActions v-if="!didComplete" class="assgnt-text-modal-footer">
        <BaseButton
          :disabled="working"
          depressed
          color="blue darken-1"
          @click="toggleSMSstatusVisibility"
          >{{ getIsSMSStatusVisible ? 'Hide' : 'Show' }} SMS Status</BaseButton
        >
        <VSpacer></VSpacer>
        <BaseButton
          :disabled="working || !laborerIdsWithValidPhone.length"
          depressed
          color="blue darken-1"
          @click="sendTexts"
          >Text Laborers</BaseButton
        >
        <BaseButton
          color="blue darken-1"
          :disabled="working"
          outline
          @click="$emit('hide')"
          >Cancel</BaseButton
        >
      </VCardActions>
    </VCard>
  </VDialog>
</template>

<script>
import lambdaApi from '@services/lambdaApi'
import { mapGetters, mapActions } from 'vuex'
import { smsStatuses } from '@constants/knack'
import pAll from 'p-all'
import _get from 'lodash/get'
import _sortBy from 'lodash/sortBy'

import VDialog from '@vuetify/VDialog'
import { VCheckbox } from 'vuetify/lib'
import { VCardText, VCardActions } from '@vuetify/VCard'
import ModalCloseButton from '@modals/ModalCloseButton'
import TextAssignment from '@modals/TextAssignment'
export default {
  name: 'TextAssgntsModal',
  components: {
    VDialog,
    VCardText,
    VCardActions,
    VCheckbox,
    ModalCloseButton,
    TextAssignment,
  },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      working: false,
      clearing: false,
      didComplete: false,
      isShowingNames: false,
      selectedAssignmentIds: [],
      allSelected: true,
    }
  },
  computed: {
    ...mapGetters([
      'getAsset',
      'getAssignedAssets',
      'getAssignmentsGroupedByAsset',
      'getReportById',
      'getReports',
      'getIsSMSStatusVisible',
      'getIsAssetAssigned',
    ]),
    showPopup: {
      get() {
        return this.show
      },
      set(val) {
        if (!val) {
          this.$emit('hide')
        }
      },
    }, // showPopup

    assetType() {
      return this.$route.params.assetType
    }, // assetType

    assgntsToText() {
      let assgnts = this.getAssignedAssets(this.assetType)
      let assgntsToText = assgnts.filter(
        (assgnt) =>
          !assgnt.ASSIGNMENT_SMS_STATUS.length ||
          [smsStatuses.UNSENT, smsStatuses.NON_CONFIRMED].includes(
            assgnt.ASSIGNMENT_SMS_STATUS
          )
      )
      return assgntsToText
    }, // assgntsToText

    laborersToText() {
      return _sortBy(this.convertAssignmentsToLaborers(this.assgntsToText), [
        'name',
      ])
    }, // laborersToText

    validatedAssignments() {
      // filter out assignments that are not selected
      var validatedAssignments = this.assgntsToText.filter((assgnt) =>
        this.selectedAssignmentIds.includes(assgnt.ID)
      )

      // filter out assignments where the laborer doesnt have a valid phone
      validatedAssignments = validatedAssignments.filter((assgnt) =>
        this.laborerIdsWithValidPhone.includes(assgnt.LABORER[0].id)
      )

      return validatedAssignments
    }, // validatedAssignments

    assgntsWithSMSIssues() {
      let assgnts = this.getAssignedAssets(this.assetType)
      let assgntsWithSMSIssues = assgnts.filter((assgnt) =>
        [smsStatuses.SEND_FAILURE, smsStatuses.NO_REPLY].includes(
          assgnt.ASSIGNMENT_SMS_STATUS
        )
      )
      return assgntsWithSMSIssues
    }, // assgntsWithSMSIssues

    laborersWithSMSIssues() {
      return this.convertAssignmentsToLaborers(this.assgntsWithSMSIssues)
    }, // laborersWithSMSIssues

    assignmentsByLaborer() {
      return this.getAssignmentsGroupedByAsset(
        this.assetType,
        this.assgntsToText
      )
    }, // assignmentsByLaborer

    laborerIdsWithValidPhone() {
      let laborerIdsWithValidPhone = Object.keys(
        this.assignmentsByLaborer
      ).filter((assetId) => {
        let laborer = this.getAsset(this.assetType, assetId)
        let phone = _get(laborer, 'SMS_NOTIFICATION_PHONE_NUMBER.full')
        return !!phone
      })
      return laborerIdsWithValidPhone
    }, // laborerIdsWithValidPhone

    numAssignedLaborers() {
      let groupedAssgnts = this.getAssignmentsGroupedByAsset(
        this.assetType,
        this.assgntsToText
      )
      return Object.keys(groupedAssgnts).length
    }, // numAssignedLaborers

    partiallySelected() {
      return (
        this.selectedAssignmentIds.length > 0 &&
        this.selectedAssignmentIds.length < this.assgntsToText.length
      )
    },
  },
  watch: {
    assgntsToText: {
      immediate: true,
      handler(newVal) {
        this.selectedAssignmentIds = newVal.map((a) => a.ID)
      },
    },
  },
  methods: {
    ...mapActions(['toggleSMSstatusVisibility', 'clearLaborerSMSStatus']),
    hide() {
      this.$emit('hide')
    }, // hide
    async sendTexts() {
      this.working = true
      try {
        let smsRequests = await this.validatedAssignments.map(
          async (assignment) => {
            const assetId = assignment.LABORER[0].id

            let firstAssgnt = this.assignmentsByLaborer[
              Object.keys(this.assignmentsByLaborer)[0]
            ][0]
            let dateString = _get(
              firstAssgnt,
              'REPORT_DATE.date_formatted',
              'tomorrow'
            )
            let assgnts = this.assignmentsByLaborer[assetId]
            let laborer = this.getAsset(this.assetType, assetId)
            let body = `Your assignment${
              assgnts.length > 1 ? 's' : ''
            } for ${dateString}:\n`
            let assgntsByStartTime = _sortBy(assgnts, [
              (assgnt) => {
                return _get(assgnt, 'START_TIME.unix_timestamp', Infinity)
              },
            ])

            body += assgntsByStartTime
              .map((assgnt) => {
                return `${assgnt.LOCATION_IDENTIFIER} - ${assgnt.LOCATION_ADDRESS}`
              })
              .join(`\n\n`)

            let notEnoughInfo =
              !assgntsByStartTime[0].LOCATION_START_.trim().length ||
              typeof assgntsByStartTime[0].START_TIME !== 'object'

            body += notEnoughInfo
              ? `\nPlease confirm your start location/time with your supervisor.\n`
              : `\nPlease arrive at the ${
                  assgntsByStartTime[0].LOCATION_START_
                } at ${this.getTimeFromTimestamp(assgntsByStartTime[0])}.\n`

            let to = `+1${_get(
              laborer,
              'SMS_NOTIFICATION_PHONE_NUMBER.full',
              ''
            )}`
            let assignmentIDs = assgnts.map((a) => a.ID)
            let laborerName = laborer.LABORER_NAME
            let startLocation = _get(
              assgntsByStartTime,
              '[0].LOCATION_START_',
              'No Location'
            )
            return () =>
              lambdaApi.post('twilioTextAssgnts', {
                body,
                to,
                assignmentIDs,
                laborerName,
                startLocation,
                reportDate: dateString,
              })
          }
        )
        // smsRequests = smsRequests.filter(r => r !== null)
        let responses = await pAll(smsRequests, { concurrency: 3 })
        return responses
      } catch (err) {
        throw new Error(err)
      } finally {
        this.didComplete = true
        this.working = false
      }
    }, // sendTexts

    getTimeFromTimestamp(assgnt) {
      let time = `${parseInt(assgnt.START_TIME.hours)}:${
        assgnt.START_TIME.minutes
      } ${assgnt.START_TIME.am_pm}`
      return time
    }, // getTimeFromTimestamp

    convertAssignmentsToLaborers(assgnts) {
      return _sortBy(
        assgnts.map((a) => {
          let laborer = this.getAsset(this.assetType, a.LABORER[0].id)
          let phone = _get(laborer, 'SMS_NOTIFICATION_PHONE_NUMBER.full', '')
          let numAssgnts = this.getIsAssetAssigned(this.assetType, laborer.ID)
          return {
            name: a.LABORER_NAME,
            phone,
            numAssgnts,
            shiftId: a.ID,
            smsStatus: a.ASSIGNMENT_SMS_STATUS,
            reportId: a.DAILY_REPORT[0].id,
            reportName: a.DAILY_REPORT[0].identifier,
            jobNumber: a.JOB_NUMBER,
          }
        }),
        (l) => {
          let parts = l.name.split(' ')
          return parts[parts.length - 1].charAt(0)
        }
      )
    }, // convertAssignmentsToLaborers
    async clearSMSStatusForAssgnt(laborer) {
      if (!laborer.shiftId) {
        console.warn('Laborer ID required to clearSMSStatus')
        return
      }
      this.clearing = true
      try {
        await this.clearLaborerSMSStatus({ shiftId: laborer.shiftId })
        this.$notify({
          type: 'success',
          title: 'SMS Status Cleared',
          text: `SMS status cleared for ${laborer.name}`,
        })
      } catch (error) {
      } finally {
        this.clearing = false
      }
    }, // clearSMSStatusForAssgnt
    toggleSelection(shiftId) {
      const index = this.selectedAssignmentIds.indexOf(shiftId)
      if (index === -1) {
        this.selectedAssignmentIds.push(shiftId)
      } else {
        this.selectedAssignmentIds.splice(index, 1)
      }
    },
    toggleSelectAll() {
      if (this.allSelected) {
        this.selectedAssignmentIds = []
      } else {
        this.selectedAssignmentIds = this.assgntsToText.map((a) => a.ID)
      }
      this.allSelected = !this.allSelected
    },
  }, // methods
}
</script>

<style lang="scss">
.text-assgnts-popup {
  .v-card__title {
    .title,
    .subtitle {
      font-family: 'Avenir', sans-serif !important;
      font-weight: 500;
      color: black;
    }
    .subtitle {
      margin-top: 0.3em;
    }
  }
  .no-phone-icon {
    color: $red;
  }
  .num-assignments {
    flex: 0 0 auto;
    margin-left: 4px;
    font-size: 0.8em;
    line-height: 15px;
    color: white;
    background-color: $orange;
    border-radius: 100% !important;
  }
}
</style>
